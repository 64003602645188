<footer>

  <div class="container">

    <div class="top-bar">

      <div class="row justify-content-sm-between align-items-center">

        <div class="col-auto">

          <a class="logo d-inline-block" routerLink="/">
            <img src="/assets/images/logo-simple.svg" width="38" height="38" alt="Логотип EVLAND" i18n-alt />
          </a>

        </div>

        <div class="col col-sm-auto">

          <div class="helping-hand d-flex d-sm-inline-flex flex-row justify-content-end align-items-center">

            <div class="label d-inline-flex me-3" i18n>Ми готові допомогти</div>

            <button (click)="openCallbackModal()" class="btn btn-primary ms-4" i18n>
              Консультація
            </button>

          </div>

        </div>

      </div>

    </div>

    <div class="content">

      <div class="row">

        <div itemscope="" itemtype="http://schema.org/Organization" class=”d-none”>
          <meta itemprop="name" content="EVLAND">
          <meta itemprop="telephone" content="+380685547461">
          <!--          <meta itemprop="email" content="support@evland.store">-->
          <meta itemprop="address" content="вулиця Газопровідна, 43, Київ, Украина" i18n-content>
          <meta itemprop="logo" content="https://evland.store/assets/images/logo-simple.svg">
          <meta itemprop="legalName" content="EVLAND">
          <link itemprop="url" href="https://evland.store" content="https://evland.store">
        </div>

        <div class="col-md-6 col-xl-4" itemscope="" itemtype="http://schema.org/Organization">
          <div class="title" i18n>Наші контакти</div>
          <div class="mb-2">
            <a href="tel:+380668898990"
               class="phone-link d-inline-flex flex-row justify-content-start align-items-center">
              <mat-icon class="icon me-2" svgIcon="custom:vodafone"></mat-icon>
              <span class="label">+380 (66) 889 89 90</span>
            </a>
          </div>
          <div class="mb-2">
            <a href="tel:+380688898998"
               class="phone-link d-inline-flex flex-row justify-content-start align-items-center">
              <mat-icon class="icon me-2" svgIcon="custom:kyivstar"></mat-icon>
              <span class="label">+380 (68) 889 89 98</span>
            </a>
          </div>
          <div class="mb-2">
            <a href="tel:+380938898998"
               class="phone-link d-inline-flex flex-row justify-content-start align-items-center">
              <mat-icon class="icon me-2" svgIcon="custom:lifecell"></mat-icon>
              <span class="label">+380 (93) 889 89 98</span>
            </a>
          </div>
          <ul class="contacts-list list-unstyled">
            <li class="contacts-list-item d-flex flex-row align-items-center">
              <mat-icon svgIcon="sax:bulk-location" class="icon"></mat-icon>
              <a href="https://goo.gl/maps/tRYu75ihAjXazRxVA" target="_blank" class="label" i18n>вулиця Газопровідна,
                43, Київ, Україна</a>
            </li>
            <!--            <li class="contacts-list-item d-flex flex-row align-items-center">-->
            <!--              <mat-icon svgIcon="sax:bulk-sms" class="icon"></mat-icon>-->
            <!--              <a href="mailto:support@evland.store" class="label">support@evland.store</a>-->
            <!--            </li>-->
            <li class="contacts-list-item d-flex flex-row align-items-center">
              <mat-icon svgIcon="sax:bulk-clock-1" class="icon"></mat-icon>
              <div class="label d-inline-flex" i18n>Пн-Пт: 10:00-18:30 <br />Сб: 10:00-17:00 <br/>Нд: Вихідний</div>
            </li>
          </ul>
        </div>

        <div *ngFor="let group of contentLinkGroups; let index = index" class="col-12 col-md-6 col-xl">
          <div [class.d-md-none]="!index" [class.d-xl-none]="!!index" class="delimiter"></div>

          <div class="title">{{ group.label }}</div>

          <ul class="content-menu-list list-unstyled" itemscope itemtype="http://schema.org/SiteNavigationElement">

            <li *ngFor="let link of group.links" class="content-menu-list-item">

              <a *ngIf="link.href; else actionLinkBlock" [class.disabled]="link.disabled" [routerLink]="link.href"
                 [routerLinkActiveOptions]="{exact: link.href === '/'}" routerLinkActive="active"
                 class="content-menu-link" itemprop="url">
                {{ link.label }}
                <meta [content]="link.label" itemprop="name">
              </a>

              <ng-template #actionLinkBlock>
                <div (click)="onActionClick(link.action)" [class.disabled]="link.disabled" class="content-menu-link">
                  {{ link.label }}
                </div>
              </ng-template>

            </li>

          </ul>

        </div>

      </div>

    </div>

    <div class="bottom-bar">

      <ul class="hint-list list-unstyled mb-3">
        <li><sup>*</sup> Ціни на сайті орієнтовні та не є публічною офертою, для отримання точної ціни зверніться до
          менеджерів.
        </li>
      </ul>

      <div class="row flex-row justify-content-between align-items-center">

        <div class="col-12 col-md-auto">

          <ul class="important-links-list list-inline" itemscope itemtype="http://schema.org/SiteNavigationElement">

            <li *ngFor="let navLink of importantNavLinks" class="list-inline-item d-flex d-md-inline-flex">

              <a [routerLink]="navLink.href" [fragment]="navLink.fragment" class="important-link" itemprop="url">
                {{ navLink.label }}
              </a>

            </li>

          </ul>

        </div>

        <div class="col-12 col-md-auto">
          <div class="social-block d-inline-flex flex-row align-items-center">

            <div class="label d-none d-sm-inline-flex" i18n>Наші соціальні мережі</div>

            <ul class="social-links-list list-inline">

              <li *ngFor="let navLink of socialNavLinks" class="list-inline-item">

                <a [href]="navLink.href" [title]="navLink.label" target="_blank" class="social-link d-inline-block">
                  <mat-icon [svgIcon]="navLink.icon" class="icon"></mat-icon>
                </a>

              </li>

            </ul>

          </div>

        </div>

      </div>

    </div>

  </div>

</footer>
